body {
  margin: 0;
  color: #212850;
  font: 400 18px/1.5 'HelveticaNeueLTPro-Roman';
}

.row {
  margin-right: 0;
  margin-left: 0;
}

h1,
h2,
h3 {
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1px;
}

h1 {
  margin: 4rem 0 4rem 0;
  text-align: center;
}

h4 {
  font-family: HelveticaNeueLTPro-Md, sans-serif !important;
  font-weight: bolder !important;
  font-style: normal;
}

h5 {
  font-size: 18px !important;
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: bolder;
  font-style: normal;
}

button,
p,
span,
div {
  font-weight: normal;
  font-style: normal;
}

label {
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: normal;
  font-style: normal;
}
sub {
  font-size: 22px;
  bottom: 0;
  margin-left: 10px;
}

.container {
  padding-right: 0;
  padding-left: 0;
}

.modal-body {
  padding: 1rem 3rem 3rem 3rem !important;
}

.modal-header {
  border-bottom: none;
  padding: 0.5rem 1rem;
}

.card-header {
  background-color: #fafafc;
  text-align: right;
  padding: 5px;
}

.card {
  border: none;
}

.accordion > .card > .card-header {
  color: #01a384;
}

.accordion > .card > .card-header:hover {
  color: #00a385;
  cursor: pointer;
  text-decoration: underline;
}

#hubspotIframe {
  position: relative;
  height: 640px;
  width: 100%;
  border: none;
}

.form-control {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 0;
  border: 1px solid #dfe1e5;
  height: 45px;
  /*border-radius: 5px;*/
  /* height: 3.6em; */
  width: 100%;
}

@media only screen and (max-width: 990px) {
  h1 {
    margin: 2rem 0;
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.4rem !important;
  }
  .modal-body {
    padding: 1rem !important;
  }

  .col-md-2 {
    flex: none;
  }
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #00A385;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 92%;
  z-index: 400;
}


ul.timeline > li {
  margin: 45px 0;
  padding-left: 20px;
}

ul.timeline > li:first-child:before {
  background: #00A385
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #00A385;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}


.hs-button {
  width: 270px;
  border: 0;
  background-color: #01a384;
  color: #ffffff;
  line-height: 40px;
  height: 45px;
  font-size: 20px;
  border-radius: 35px;
  cursor: pointer;
}

.actions {
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 17px 0px;
  text-align: center;
}

.hs-input {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #dfe1e5;
  border-radius: 5px;
  /* height: 3.6em; */
  width: 100%;
}

.hs-fieldtype-text > label > span {
  color: #212850;
}
.hs-fieldtype-textarea > label > span {
  color: #212850;
}

.inputs-list > li {
  display: block !important;
}

.inputs-list:first-child {
  padding-top: 6px;
}

.inputs-list {
  margin: 0 0 5px;
  padding-left: 5px !important;
}

.field {
  margin-bottom: 18px;
}

.hs-form-field label:not(.hs-error-msg) {
  color: #939393;
}

.legal-consent-container .hs-form-booleancheckbox-display > span {
  display: block !important;
  margin-left: 20px !important;
}

.hs-input:not([type='checkbox']):not([type='radio']) {
  width: 100% !important;
}

.inputs-list label {
  display: block;
  float: none;
  width: auto;
  padding: 0;
  line-height: 18px;
  text-align: left;
  white-space: normal;
  font-weight: normal;
  font-size: 14px;
}

.hs-error-msgs label {
  color: #f2545b;
}

input.hs-input.error,
div.field.error input,
div.field.error textarea,
div.field.error .chzn-choices,
textarea.hs-input.error,
select.hs-input.error {
  border-color: #c87872;
}

fieldset {
  max-width: none !important;
}

.legal-consent-container .hs-form-booleancheckbox-display p {
  color: #212850;
}

.legal-consent-container .hs-form-booleancheckbox-display input {
  float: left;
  width: auto;
  height: auto;
}

.input > textarea {
  height: 70px;
}

.hs-form-required {
  color: #c87872 !important;
}

@media only screen and (max-width: 990px) {
  .hs-button {
    width: 100%;
    font-size: 15px;
  }
}
